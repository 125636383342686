import { graphql } from 'gatsby';
import { PostLayout } from '../../components/entities/post-layout';
import { createGatsbyPageComponent } from '@shapeable/ui';

export default createGatsbyPageComponent('Post', { layout: PostLayout });

export const QUERY = graphql`
  query PostQuery($id: ID!) {
    platform {
      post(id: $id) {
        id slug path 
        name name_fr 
        title title_fr 
        pretitle pretitle_fr
        subtitle subtitle_fr
        published
        edited
        content { plain text }
        content_fr { plain text }
        openGraph { title description { plain } image { url2x thumbnails { full { url url2x } } } }
        intro { plain text }
        intro_fr { plain text }
        outro { text }
        outro_fr { text }
        videos {
          id name url
          thumbnail { id url url2x }
        }
        imageAssets {
          id name image { id url url2x }
        }
        organisations {
          id name slug path
          logo { id url url2x }
        }
        authors {
          id name slug path
          organisation { id name }
          linkedin
          photo { id url url2x }
          positions {
            id
            primary
            positions {
              id 
              name 
              name_fr
            }
            organisation { id name }
          }
        }
        people {
          id name slug path
          organisation { id name }
          linkedin
          photo { id url url2x }
          positions {
            id
            primary
            positions {
              id 
              name 
              name_fr
            }
            organisation { id name }
          }
        }
        embeds {
          citations {
            slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
          }
          pages {
            id name slug path
            openGraph {
              title description { plain }
              image { url url2x }
            }
          }
          people {
            id name slug path
            organisation { id name }
            position
            photo { id url url2x }
          }
          imageAssets {
            id
            slug
            image {
              id url url2x width height
            }
          }
        }
        
        banners {
          id name slug title description { text } alternateText
          image { 
            id url url2x width height
          }
        }
      }
    }
  }
`;
